import React from "react"
import Layout from "../components/layout"
import { bigHeader } from "../components/Layout/contentHeader"
import Heading from "../components/Layout/heading"
import { highlightedList } from "../components/Layout/highlightedList"
import MaxWidth from "../components/Layout/maxWidth"
import SEO from "../components/seo"
import { css } from "linaria"
import fitnessGuy from "../images/sport_audience_image.png"
import corp from "../images/corporate_audience_image.png"
import outdoor from "../images/outdoor_audience_image.png"
import { styled } from "linaria/react"

export default function TargetAudience() {
  return (
    <Layout>
      <SEO title="Target audience" />
      <Heading>
        <h1>Target audience</h1>
      </Heading>
      <MaxWidth>
        <h2 className={bigHeader}>21. century businesses</h2>

        <Flex>
          <div>
            <p>
              Our clients are start-ups, academic spin-offs as well as big
              companies willing to introduce new, disruptive technologies,
              innovative services and products into the market. Currently, our
              services are covering many different industries:
            </p>

            <ol className={highlightedList}>
              <li>
                <span>01 |</span> Pharma & Biotech
              </li>
              <li>
                <span>02 |</span> Medtech & Diagnostics
              </li>
              <li>
                <span>03 |</span> Dietary supplements
              </li>
              <li>
                <span>04 |</span> Nutrition, food & drink companies
              </li>
              <li>
                <span>05 |</span> Lifestyle industries
              </li>
            </ol>
          </div>

          <div className={imageGrid}>
            <div class="box1">
              <img src={fitnessGuy} alt="sportman running image" />
            </div>
            <div class="box2">
              <img src={corp} alt="office_meeting_image" />
            </div>
            <div class="box3">
              <img src={outdoor} alt="outdoor_active_image" />
            </div>
          </div>
        </Flex>
      </MaxWidth>
    </Layout>
  )
}

const Flex = styled.section`
  display: flex;
  @media (max-width: 900px) {
    flex-wrap: wrap;
  }
`
const imageGrid = css`
  flex: 1 0 55%;
  margin-left: 1em;
  @media (max-width: 900px) {
    flex: 0 0 100%;
    margin-left: 0;
    margin-top: 3em;
  }
  & {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0.4em;
    grid-row-gap: 0.4em;
  }

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }

  .box1 {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 1;
    grid-row-end: 3;
  }

  .box2 {
    grid-column-start: 2;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
  }

  .box3 {
    grid-column-start: 2;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 3;
  }
`
