import { css } from "linaria"
import colors from "../../styles/colors"

export const highlightedList = css`
  &,
  li {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  li {
    margin-bottom: 1em;
    color: ${colors.lightText};
    span {
      font-weight: 700;
      color: ${colors.darkBlue};
    }
  }
`
