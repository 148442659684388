import { css } from "linaria"
import { darken } from "polished"
import React from "react"
import MaxWidth from "./maxWidth"

export default function Heading({ children, ...rest }) {
  return (
    <header className={header} {...rest}>
      <MaxWidth>{children}</MaxWidth>
    </header>
  )
}

const header = css`
  padding: 1.5em;
  font-size: 1em;
  background: #fafafb;
  border: 1px solid ${darken(0.1, "#fafafb")};
  border-width: 1px 0;
  margin-bottom: 2em;
`
